import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({ credentialsFull: false, hasPaid: false });
  const navigate = useNavigate();

  const verifyToken = useCallback(async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verify-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        await response.json(); // Keep this line if the JSON response has side effects.
        setIsAuthenticated(true);
        // Fetch user details
        const detailsResponse = await fetch(`${process.env.REACT_APP_API_URL}/user-details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (detailsResponse.ok) {
          const detailsData = await detailsResponse.json();
          setUserDetails(detailsData);
        }
      } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        setUser(null);
        setIsAuthenticated(false);
        navigate('/login');
      }
    } catch (error) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      setUser(null);
      setIsAuthenticated(false);
      navigate('/login');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');
    if (storedUser && accessToken) {
      setUser(JSON.parse(storedUser));
      verifyToken(accessToken);
    } else {
      setLoading(false);
    }
  }, [verifyToken]);

  const login = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('access_token', userData.access_token);
    setUser(userData);
    setIsAuthenticated(true);
    setLoading(false);
    navigate('/');
  };

  const logout = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        setUser(null);
        setIsAuthenticated(false);
        setLoading(false);
        navigate('/login');
      }
    } catch (error) {
      // Handle error
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, loading, userDetails }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
