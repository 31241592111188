import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';
import Login from './Login';
import Search from './Search';
import Landing from './Landing';
import Signup from './Signup';
import Setup from './Setup';
import Guide from './Guide';
import Payment from './Payment';
import About from './About';
import Account from './Account';
import RequestPasswordReset from './RequestPasswordReset';
import ResetPassword from './ResetPassword';
import PlanSelection from './PlanSelection';
import Return from './Return';
import Footer from './Footer';
import Receiving from './Receiving';
import Attribution from './Attribution';


import TermsOfService from './TermsOfService';
import PrivacyPolicy from './Privacy';



import React from 'react';
import './index.css';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './SubscriptionOnlyRoute';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-grow w-full">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/request-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          

          <Route
            path="/search"
            element={
              <ProtectedRoute>
                <Search />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receiving"
            element={
              <ProtectedRoute>
                <Receiving />
              </ProtectedRoute>
            }
          />
          <Route
            path="/setup"
            element={
              <PrivateRoute>
                <Setup />
              </PrivateRoute>
            }
          />
          <Route
            path="/return"
            element={
              <PrivateRoute>
                <Return />
              </PrivateRoute>
            }
          />
          <Route
            path="/guide"
            element={
              <PrivateRoute>
                <Guide />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />
          <Route
            path="/plan-selection"
            element={
              <PrivateRoute>
                <PlanSelection />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
      <Attribution />
      <Footer />
    </div>
  );
}

export default App;
