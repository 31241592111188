import { useState, useCallback } from 'react';

const useBarcodeHandler = () => {
  const [error, setError] = useState('');

  const handleScan = useCallback(async (scannedBarcode, setProduct, setLoading) => {
    if (!scannedBarcode) {
      setError('Please enter a valid barcode.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/search-product/barcode/${scannedBarcode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const product = await response.json();
      if (response.ok) {
        setProduct(product);
        setError(''); // Clear any existing error
      } else {
        setError(product.error || 'Product not found');
      }
    } catch (error) {
      console.error('Error fetching product:', error);
      setError('Error fetching product');
    } finally {
      setLoading(false);
    }
  }, []);

  return { handleScan, error, setError };
};

export default useBarcodeHandler;
