import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TailSpin } from 'react-loader-spinner';
import useBarcodeHandler from './useBarcodeHandler';

const Search = () => {
  const [search, setSearch] = useState('');
  const [product, setProduct] = useState(null);
  const [searchType, setSearchType] = useState('barcode');
  const [discrepancyNote, setDiscrepancyNote] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { handleScan, error, setError } = useBarcodeHandler();
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);
  const lastInputTime = useRef(Date.now());

  const handleError = (message) => {
    setError(message);
    setLoading(false); // Ensure loading is stopped on error
  };

  const fetchProduct = useCallback(async () => {
    if (!search) {
      handleError('Search term is empty');
      return;
    }

    setProduct(null);
    setError(null);
    setLoading(true); // Set loading to true when starting the search 

    const token = localStorage.getItem('access_token');
    if (!token) {
      handleError('User is not authenticated');
      return;
    }

    const endpoint = `${process.env.REACT_APP_API_URL}/search-product/${searchType}/${search}`;

    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        handleError('Failed to fetch product');
        return;
      }

      const data = await response.json();

      if (data.error) {
        handleError(data.error);
      } else {
        setProduct(data);
      }
    } catch (err) {
      handleError('An error occurred while fetching product details.');
    } finally {
      setLoading(false); // Set loading to false after the search is complete
    }
  }, [search, searchType, setError]);

  const handleChange = (e) => {
    const value = e.target.value;
    const currentTime = Date.now();
    const timeDiff = currentTime - lastInputTime.current;
    lastInputTime.current = currentTime;
    setSearch(value);

    // Clear existing timeout if there is one
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // If the time between keystrokes is very short, assume a barcode scanner is being used
    if (timeDiff < 100) { // Adjust this threshold as necessary
      setLoading(true);
      handleScan(value, (product) => {
        setProduct(product);
        setLoading(false);
      }, setLoading);
    } else {
      // Set a new timeout for debouncing manual input
      debounceTimeout.current = setTimeout(() => {
        setLoading(true); // Start loading before search begins
        if (searchType === 'barcode') {
          handleScan(value, (product) => {
            setProduct(product);
            setLoading(false); // Stop loading after scan completes 
          }, setLoading);
        } else {
          fetchProduct();
        }
      }, 4000); // Adjust the delay as needed for manual input
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission behavior
      setLoading(true); // Start loading before search begins
      if (searchType === 'barcode') {
        handleScan(search, (product) => {
          setProduct(product);
          setLoading(false); // Stop loading after scan completes
        }, setLoading);
      } else {
        fetchProduct();
      }
      setSearch(''); // Optionally clear the search input after processing
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from submitting and causing a page reload
    setLoading(true); // Start loading before search begins
    if (searchType === 'barcode') {
      handleScan(search, (product) => {
        setProduct(product);
        setLoading(false); // Stop loading after scan completes
      }, setLoading);
    } else {
      fetchProduct();
    }
  };

  const handleDiscrepancy = async () => {
    if (!product || !product.sku) return;

    const token = localStorage.getItem('access_token');
    if (!token) {
      handleError('User is not authenticated');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-discrepancy/${product.sku}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const result = await response.json();
      if (result.error) {
        handleError(result.error);
      } else {
        alert('Discrepancy reported successfully!');
      }
    } catch (err) {
      handleError('An error occurred while reporting the discrepancy.');
    }
  };

  const handleDiscrepancyNoteUpdate = async () => {
    if (!product || !product.sku) return;

    const token = localStorage.getItem('access_token');
    if (!token) {
      handleError('User is not authenticated');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-discrepancy-note/${product.sku}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ note: discrepancyNote }),
      });
      const result = await response.json();
      if (result.error) {
        handleError(result.error);
      } else {
        alert('Discrepancy note updated successfully!');
        setDiscrepancyNote('');
      }
    } catch (err) {
      handleError('An error occurred while updating the discrepancy note.');
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div className="max-w-screen-lg mx-auto p-4">
      {error && (
        <div className="bg-red-500 text-white p-4 rounded mb-4">
          {error}
        </div>
      )}
      <div className="flex space-x-4 mb-4">
        <button
          className={`px-4 py-2 rounded ${searchType === 'sku' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-black'}`}
          onClick={() => setSearchType('sku')}
        >
          SKU
        </button>
        <button
          className={`px-4 py-2 rounded ${searchType === 'barcode' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-black'}`}
          onClick={() => setSearchType('barcode')}
        >
          Barcode
        </button>
      </div>

      <form onSubmit={handleSubmit} className="mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">Enter {searchType.toUpperCase()}</label>
          <div className="relative">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded"
              placeholder={`Input ${searchType}`}
              value={search}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              required
            />
            {search && (
              <button
                type="button"
                className="absolute top-0 right-0 p-2 bg-transparent border-none cursor-pointer"
                onClick={() => {
                  setSearch('');
                  setProduct(null);
                  setError(null);
                }}
              >
                ♻️
              </button>
            )}
          </div>
        </div>
      </form>

      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      )}

      {product && !loading && (
        <div className="flex flex-col items-center space-y-4">
          <p className="text-2xl font-bold mb-4">
            <strong>Quantity:</strong> <span>{product.quantity}</span>
          </p>

          {product.image_url && (
            <img src={product.image_url} alt={product.name} className="w-64 h-64 object-contain mb-4" />
          )}

          <p className="text-xl font-bold mb-2">
            <strong>Name:</strong> {product.name}
          </p>
          <p className="text-lg mb-2">
            <strong>Description:</strong> 
            {showFullDescription || !product.description 
              ? product.description 
              : `${product.description.slice(0, 100)}...`}
          </p>
          <button
            className="bg-white text-black border border-gray-300 px-4 py-2 rounded hover:shadow-lg transition duration-300"
            onClick={toggleDescription}
          >
            {showFullDescription ? 'Read Less' : 'Read More'}
          </button>

          <button
            className="bg-white text-black border border-gray-300 px-4 py-2 rounded hover:shadow-lg transition duration-300 mb-4 w-full"
            onClick={handleDiscrepancy}
          >
            Report a Discrepancy
          </button>
          <textarea
            placeholder="Enter discrepancy notes here..."
            value={discrepancyNote}
            onChange={(e) => setDiscrepancyNote(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-4"
          />
          <button
            className="bg-white text-black border border-gray-300 px-4 py-2 rounded hover:shadow-lg transition duration-300 w-full"
            onClick={handleDiscrepancyNoteUpdate}
          >
            Update Discrepancy Note
          </button>
        </div>
      )}

      {error && (
        <div className="text-red-500 mt-4 text-center">{error}</div>
      )}
    </div>
  );
};

export default Search;
