import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white py-6 mt-10 shadow-inner w-full">
      <div className="container mx-auto text-center px-4">
        <div className="flex justify-center space-x-6">
          <Link to="/privacy-policy" className="text-gray-800 hover:text-orange-600">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="text-gray-800 hover:text-orange-600">
            Terms of Service
          </Link>
          <a href="mailto:support@m2instacount.com" className="text-gray-800 hover:text-orange-600">
            Contact Us
          </a>
        </div>
        <p className="text-gray-600 mt-4">© {new Date().getFullYear()} M2 InstaCount. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
