import React from 'react';
import './index.css'; // Ensure this imports the CSS with the font

const Attribution = () => {
    return (
        <div className="fixed-bottom-right fixed bottom-4 right-4 bg-gray-700 bg-opacity-75 text-white py-2 px-3 rounded flex items-center sm:bottom-6 sm:right-6">
            <a href="https://collinburleigh.com" target="_blank" rel="noopener noreferrer" className="text-sm sm:text-base bebas-neue-regular">
                Made by Collin
            </a>
        </div>
    );
};

export default Attribution;


