import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PlanSelection = () => {
  const navigate = useNavigate();
  const [plan, setPlan] = useState('monthly');

  const handleNext = () => {
    navigate('/payment', { state: { plan } });
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 p-4 sm:p-6">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md">
        <h1 className="text-4xl font-extrabold text-center mb-6 text-black">Select Your Plan</h1>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">Choose your plan:</label>
          <div
            className={`flex justify-between items-center mb-4 p-4 border rounded-lg cursor-pointer transition duration-300 ${
              plan === 'monthly' ? 'bg-gray-100 border-gray-300' : 'bg-white border-gray-300'
            }`}
            onClick={() => setPlan('monthly')}
          >
            <div>
              <h2 className="text-lg font-semibold">Monthly</h2>
              <p className="text-gray-600">$9.99/month</p>
            </div>
          </div>
          <div
            className={`flex justify-between items-center p-4 border rounded-lg cursor-pointer transition duration-300 ${
              plan === 'yearly' ? 'bg-gray-100 border-gray-300' : 'bg-white border-gray-300'
            }`}
            onClick={() => setPlan('yearly')}
          >
            <div>
              <h2 className="text-lg font-semibold">Yearly</h2>
              <p className="text-gray-600">$74.99/year</p>
            </div>
          </div>
        </div>
        <button 
          type="button" 
          onClick={handleNext} 
          className="bg-orange-500 text-white px-4 py-2 rounded w-full hover:bg-orange-600 transition duration-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PlanSelection;
