import React from 'react';

const About = () => {
  return (
    <div className="bg-white text-black p-6 md:p-16">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-4xl sm:text-5xl font-bold text-center mb-8 text-gray-900">About M2 InstaCount</h2>
        
        <p className="mb-6 text-lg sm:text-xl text-gray-700">
          M2 InstaCount is a powerful tool designed to revolutionize inventory management for Magento 2 stores. It addresses key pain points by enabling barcode-driven inventory checks and real-time discrepancy management, features not natively supported in Magento 2. This is particularly valuable for end-of-year and periodic inventory tasks, where accuracy and efficiency are crucial.
        </p>

        <p className="mb-6 text-lg sm:text-xl text-gray-700">
          One of the standout features of M2 InstaCount is its ability to make barcodes searchable attributes in Magento 2, allowing you to compare actual stock levels with expected values seamlessly. This makes it easier to identify and correct discrepancies before they become costly errors. The application also streamlines the order receiving process by scanning barcodes, fetching SKUs, and generating CSV files for easy comparison with supplier invoices.
        </p>

        <p className="mb-6 text-lg sm:text-xl text-gray-700">
          Whether you're managing a small or large Magento 2 store, M2 InstaCount simplifies your inventory processes, helping you maintain accuracy and efficiency year-round.
        </p>

        <div className="bg-gray-100 p-6 sm:p-8 rounded-lg shadow-md mb-8">
          <h3 className="text-2xl font-bold text-center mb-4 text-gray-900">Upcoming Features</h3>
          <ul className="list-disc list-inside ml-4 text-lg sm:text-xl text-gray-700">
            <li>Enhanced handling of items that have been purchased but not yet shipped.</li>
            <li>Direct modification of inventory quantities within Magento when discrepancies are found, streamlining the correction process.</li>
          </ul>
        </div>

        <div className="bg-gray-100 p-6 sm:p-8 rounded-lg shadow-md mb-8">
          <h3 className="text-2xl font-bold text-center mb-4 text-gray-900">User Stories</h3>
          <p className="mb-4 text-lg sm:text-xl text-gray-700">
            "M2 InstaCount has transformed our year-end inventory process. The ability to search by barcode has saved us countless hours." - Tyler, Operations Manager
          </p>
          <p className="mb-4 text-lg sm:text-xl text-gray-700">
            "The real-time updates and discrepancy management features have made our inventory more accurate and reliable." - Cam, Magento Store Owner
          </p>
        </div>

        <div className="bg-gray-100 p-6 sm:p-8 rounded-lg shadow-md mb-8">
          <h3 className="text-2xl font-bold text-center mb-4 text-gray-900">Dedicated Support</h3>
          <p className="mb-4 text-lg sm:text-xl text-gray-700">
            Our dedicated support team is here to assist you with any issues or questions. We value user feedback and continuously work to improve M2 InstaCount to better meet your needs. Whether you need help with a specific use case or have suggestions for new features, we're here to help.
          </p>
        </div>

        <div className="text-center p-4 sm:p-8 md:p-16 bg-gray-50 rounded-lg shadow-md">
          <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-black">Try M2 InstaCount</h3>
          <p className="text-lg sm:text-xl text-gray-700 mb-6">Start your two-week free trial today and see the difference M2 InstaCount can make in your inventory management.</p>
          <a href="/signup" className="bg-orange-500 text-white px-4 sm:px-6 md:px-8 py-2 sm:py-3 md:py-4 rounded-lg text-base sm:text-lg md:text-xl shadow-lg hover:bg-orange-600 transition duration-300 no-underline inline-block max-w-xs w-full sm:w-auto">
            Get Started with M2 InstaCount
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
