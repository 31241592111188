import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from './AuthContext';
import logo from './M2-box-logo.png';

const Navigation = () => {
  const { user, logout, userDetails } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white p-4 shadow-md sticky top-0 z-50 w-full">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} alt="M2 InstaCount" className="h-12" />
        </Link>
        <div className="hidden md:flex space-x-6 items-center">
          {user && userDetails.hasPaid && (
            <span className="text-green-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Payment Valid</span>
          )}
          {user && userDetails.credentialsFull && (
            <span className="text-green-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Credentials Added</span>
          )}
          <Link to="/about" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">About</Link>
          {user ? (
            <>
              {userDetails.credentialsFull && userDetails.hasPaid && (
                <>
                  <Link to="/search" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Search</Link>
                  <Link to="/receiving" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Receiving</Link>
                </>
              )}
              {!userDetails.credentialsFull && (
                <>
                  <Link to="/guide" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Guide</Link>
                  <Link to="/setup" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Setup</Link>
                </>
              )}
              {!userDetails.hasPaid && (
                <Link to="/plan-selection" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Payment</Link>
              )}
              <Link to="/account" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Account</Link>
              <button onClick={logout} className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Login</Link>
              <Link to="/signup" className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">Signup</Link>
            </>
          )}
        </div>
        <button
          className="md:hidden text-black focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      <div className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:hidden bg-white p-6 shadow-lg w-64`}>
        <button
          className="text-black focus:outline-none self-end mb-4"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {user && userDetails.hasPaid && (
          <span className="block text-green-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Payment Valid</span>
        )}
        {user && userDetails.credentialsFull && (
          <span className="block text-green-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Credentials Added</span>
        )}
        <hr className="my-2 border-gray-300" />
        <Link to="/about" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">About</Link>
        {user ? (
          <>
            {userDetails.credentialsFull && userDetails.hasPaid && (
              <>
                <Link to="/search" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Search</Link>
                <Link to="/receiving" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Receiving</Link>
              </>
            )}
            {!userDetails.credentialsFull && (
              <>
                <Link to="/guide" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Guide</Link>
                <Link to="/setup" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Setup</Link>
              </>
            )}
            {!userDetails.hasPaid && (
              <Link to="/plan-selection" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Payment</Link>
            )}
            <Link to="/account" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Account</Link>
            <button onClick={logout} className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Logout</button>
          </>
        ) : (
          <>
            <Link to="/login" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Login</Link>
            <Link to="/signup" className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">Signup</Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
